import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Button,
  VStack,
  HStack,
  Spacer,
  Heading,
  Image
} from '@chakra-ui/react';
import DownloadIos from './Assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
const DownloadAppModal = ({ isOpen, onClose, message }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="90%">
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Heading as="h2" size="xl" textAlign="center" mb={4}>
              Download the iOS App
            </Heading>
            <Text>{message}</Text>
            <Text>Download the VaicAI app to get started!</Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack width='100%'>
            <Spacer />
            <Button alignSelf='center' justifySelf='center'>
              <a href="https://apps.apple.com/us/app/vaicai/id6443838892" className='appstore'>
                <Image src={DownloadIos} alt="App Store" />
              </a>
            </Button>
            <Spacer />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DownloadAppModal;