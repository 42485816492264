import { createClient } from 'contentful';

class ContentfulImageLoader {
  constructor(spaceId, accessToken) {
    this.client = createClient({
      space: spaceId,
      accessToken: accessToken,
    });
  }

  async fetchImageURL(assetId) {
    try {
      const asset = await this.client.getAsset(assetId);
      if (asset && asset.fields && asset.fields.file && asset.fields.file.url) {
        return `https:${asset.fields.file.url}`;
      } else {
        throw new Error('URL for asset not available.');
      }
    } catch (error) {
      throw error;
    }
  }
}

export default ContentfulImageLoader;