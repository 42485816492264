import React from 'react';
import { Box, Image, Text, VStack, HStack } from '@chakra-ui/react';

const StepCard = ({ image, title, subtitle, body }) => {
  return (
    <Box
    //   borderWidth="1px"
    //   borderRadius="lg"
    //   overflow="hidden"
    //   p={5}
    //   boxShadow="sm"
      width={316}
      height={48}
      p={2} 
      shadow="md" 
      borderRadius="8"
      textAlign="center"
      bg="white"
    >
      <VStack>
        <Image boxSize="50px" src={image} alt={title} />
        <HStack>
            <Text fontWeight="bold" fontSize="16">
            {title}
            </Text>
            <Text fontSize="16" color="black">
                {subtitle}
            </Text>
        </HStack>
        <Text textAlign="center" fontSize={16} color="black" py={5}>{body}</Text>
      </VStack>
    </Box>
  );
};

export default StepCard;