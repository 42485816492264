import React from "react";
import logo from './logo.png'; // Tell webpack this JS file uses this image
import appstore from './Assets/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg'
import { useParams } from "react-router-dom";
import {Text, Box, Header, Button} from 'grommet';
import SupportLayer from "./SupportLayer";
import { Grommet } from 'grommet';
import './Share.css';

class Wrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: [props.code]
        }
    }

    render(){
        return (
            <Grommet full className="main">
                <Header background="brand">
                <SupportLayer className="header-item"/>
                </Header>
                <div className="text-align:center bod">
                    <p>
                    <img src={logo} alt="Logo" className="logo"/>
                    <br></br><br></br>
                    <br></br><br></br>
                    Your Friend Sent You A Code<br></br>
                    Copy it And Enter It In The App To Get Started!
                    <br></br><br></br>
                    <Box direction="row" justify="center" alignContent="center">
                        <Text color="white" size="xxlarge">{this.state.code}</Text>
                        
                        <Button color="black" label="Copy" primary onClick={() => {navigator.clipboard.writeText(this.state.code)}}/>
                    </Box>
                    <br></br><br></br>
                    <a href="https://apps.apple.com/us/app/vaicai/id6443838892">
                        <img src={appstore} alt="App Store" className="appstore"/>
                    </a>
                    </p>
                </div>
                
            </Grommet>
        )
    }
}

export default function Share(){
    let { code } = useParams();
    
    return <Wrapper code={code}/>
}