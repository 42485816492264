import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Image,
  Text,
  VStack,
  Button,
  Flex
} from '@chakra-ui/react';
import WelcomeBG from './Assets/referral-modal-bg.jpg';

const WelcomeModal = ({ isOpen, onClose, message, onSignUpClick, onLoginClick }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="none">
      <ModalOverlay bg="blackAlpha.600" />
      <ModalContent 
        borderRadius="lg" 
        overflow="hidden" 
        mx={4} // Changed from m={4} to mx={4}
        my={4} // Added vertical margin
        width={["calc(100% - 32px)", "calc(100% - 32px)", "612px"]}
        maxWidth="612px"
      >
        <Box position="relative">
          <Image
            src={WelcomeBG}
            alt='Background'
            objectFit="cover"
            width="100%"
            height="300px"
          />
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg="rgba(0, 0, 0, 0.5)"  // Semi-transparent overlay for better text visibility
          >
            {/* <Image
              src={WelcomeBG}
              width="80%"
              height="auto"
              alt="Company Logo"
              mb={4}
            /> */}
            <Text fontSize="32px" fontWeight="bold" color="white" textAlign="center">
              Welcome to VaicAI
            </Text>
            <Text fontSize="16px" color="white" textAlign="center" mt={2}>
              {message}
            </Text>
          </Box>
        </Box>
        <VStack px={2} py={4} spacing={4}> // Changed from p={2} to px={2} py={4}
          <Button p={4} variant='solid' background='#5F22D9' color='white' size="lg" width="100%" onClick={onSignUpClick}>
            Sign Up
          </Button>
          <Button p={4} variant='solid' colorScheme='yellow' size="lg" width="100%" onClick={onLoginClick}>
            Log in
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default WelcomeModal;