import React from "react";
import logo from './logo.png';

class TermsAndConditions extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                 <h1>Terms and Conditions of Use for VaicAI Mobile App</h1>
                 <p>
                    Welcome to VaicAI! These Terms and Conditions (hereinafter referred to as "Terms") govern your use of the VaicAI mobile application (the "App"), owned and operated by VaicAI Inc. (hereinafter referred to as "we," "us," or "our"). By downloading, installing, accessing, or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the App.

                    Account Registration:<br></br>
                    a. To access certain features of the App, you may be required to create a user account. You agree to provide accurate and up-to-date information during the registration process.<br></br>
                    b. You are solely responsible for maintaining the confidentiality of your account credentials and for any activities or actions occurring under your account.<br></br>
                    <br></br>
                    App Usage:<br></br>
                    a. The VaicAI App provides a platform for purchasing flights and hotel reservations. The accuracy, availability, and pricing of the services are subject to change without notice. We do not guarantee the availability or prices of any particular flight or hotel reservation.<br></br>
                    b. You agree to use the App only for lawful purposes and in compliance with all applicable laws and regulations.<br></br>
                    c. You shall not use the App to transmit any harmful, offensive, or unlawful content or engage in any activity that may disrupt or interfere with the proper functioning of the App.<br></br>
                    <br></br>
                    Payments and Transactions:<br></br>
                    a. The App may offer payment processing services to facilitate your transactions. By using these services, you agree to comply with the applicable payment provider's terms and conditions.<br></br>
                    b. We are not responsible for any errors or issues arising from the payment processing services or the third-party payment providers. Any disputes or concerns regarding payments should be directed to the respective payment provider.<br></br>
                    <br></br>
                    Third-Party Services and Content:<br></br>
                    a. The App may include links to third-party websites, services, or content that are not owned or controlled by us. We do not endorse, guarantee, or assume any responsibility for such third-party services or content.<br></br>
                    b. You acknowledge and agree that your use of any third-party services or content is at your own risk, and you shall be subject to the terms and conditions of those third parties.<br></br>
                    <br></br>
                    Intellectual Property:<br></br>
                    a. The App and its contents, including but not limited to text, graphics, images, logos, trademarks, and software, are the property of VaicAI or its licensors and are protected by intellectual property laws.<br></br>
                    b. You are granted a limited, non-exclusive, non-transferable license to use the App and its contents solely for personal and non-commercial purposes. You shall not modify, distribute, reproduce, publish, or create derivative works based on the App without our prior written consent.<br></br>
                    <br></br>
                    Privacy Policy:<br></br>
                    a. We respect your privacy and handle your personal information in accordance with our Privacy Policy. By using the App, you consent to our collection, use, and disclosure of your personal information as described in the Privacy Policy.
                    <br></br><br></br>
                    Termination:<br></br>
                    a. We reserve the right to suspend, terminate, or discontinue your access to the App at any time and for any reason without prior notice.<br></br>
                    b. You may terminate your account and discontinue using the App by uninstalling the App from your device.<br></br>
                    <br></br>
                    Limitation of Liability:<br></br>
                    a. To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the App, including but not limited to any errors, interruptions, or inaccuracies in the services provided through the App.
                    <br></br><br></br>
                    Modifications to the Terms:<br></br>
                    a. We reserve the right to modify or update these Terms at any time. The updated Terms will be effective upon posting on the App. Your continued use of the App after the posting of the updated Terms constitutes your acceptance of the revised Terms.
                    <br></br><br></br>
                    Governing Law and Dispute Resolution:<br></br>
                    a. These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where VaicAI is registered.<br></br>
                    b. Any dispute arising out of or relating to these Terms or your use of the App shall be submitted to binding arbitration in accordance with the rules of the relevant arbitration authority.<br></br>
                    <br></br>
                    Entire Agreement:<br></br>
                    These Terms constitute the entire agreement between you and VaicAI regarding the use of the App and supersede any prior agreements or understandings.<br></br>

                    If you have any questions or concerns regarding these Terms, please contact us at [support@vaicai.com].
                 </p>
            </div>
        )
    }
}

export default TermsAndConditions;