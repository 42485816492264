import React, { useRef, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Fix for default marker icon issues in react-leaflet with Webpack
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const MapView = ({ latitude, longitude, width, height, borderRadius }) => {
    const mapRef = useRef(null);
  
    useEffect(() => {
      if (mapRef.current) {
        const map = L.map(mapRef.current, {
          center: [latitude, longitude],
          zoom: 13,
          zoomControl: false, // Hide zoom controls
          attributionControl: false, // Hide attribution
        });
  
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: false, // Hide attribution
        }).addTo(map);
  
        L.marker([latitude, longitude]).addTo(map);
  
        // Disable interactions for small maps
        if (parseInt(width) < 100 || parseInt(height) < 100) {
          map.dragging.disable();
          map.touchZoom.disable();
          map.doubleClickZoom.disable();
          map.scrollWheelZoom.disable();
          map.boxZoom.disable();
          map.keyboard.disable();
          if (map.tap) map.tap.disable();
        }
  
        return () => {
          map.remove();
        };
      }
    }, [latitude, longitude, width, height]);
  
    return (
      <Box
        ref={mapRef}
        width={width}
        height={height}
        borderRadius={borderRadius}
        overflow="hidden"
      />
    );
  };
  
  export default MapView;