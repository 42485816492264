import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PaymentInformation from './components/PaymentInformation';
import Landing from './components/Landing';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SeatMaps from './components/SeatMaps';
import ContactUs from './components/ContactUs';
import Share from './components/Share';
import TermsAndConditions from './components/TermsAndConditions';
import theme from './theme';
import { ChakraProvider } from '@chakra-ui/react';
import { getAnalytics } from "firebase/analytics";
import firebase from './firebase/config';
import TripView from './components/TripView/TripView';
// Initialize Firebase Authentication and get a reference to the service
const analytics = getAnalytics(firebase.app)

/* <Route path="/flight/payment/:tripVRI/:clientToken/:intentId/:cost" element={<PaymentInformation/>}/>
        <Route path="/flight/seatmaps/:tripVRI" element={<SeatMaps/>}/>
        */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path='/test' element={<App/>}/>
        <Route path="/support" element={<ContactUs/>} />
        <Route path="/:landingPath/trip/:code" element={<Landing />} />
        <Route path="/:landingPath/:code" element={<Landing />} />
        <Route path="/terms" element={<TermsAndConditions/>} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

