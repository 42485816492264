import React, { useState, useEffect } from 'react';
import { Img as Image } from 'react-image';
import ContentfulImageLoader from './ContentfulImageLoader';

const ContentfulImageView = ({ spaceId, accessToken, assetId, width, height, placeholder }) => {
  const [imageURL, setImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loader = new ContentfulImageLoader(spaceId, accessToken); // Assuming this is a valid class or function to load images
    loader
      .fetchImageURL(assetId)
      .then(url => {
        setImageURL(url);
        setIsLoading(false);
      })
      .catch(error => {
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  }, [spaceId, accessToken, assetId]);

  return (
    <div style={{ width: `${width}px`, height: `${height}px`, overflow: 'hidden' }}>
      {isLoading && <div>{placeholder}</div>}
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      {imageURL && (
        <Image
          src={imageURL}
          loader={<div>{placeholder}</div>} // What to display while loading
          unloader={<div>Error loading image</div>} // What to display if there is an error
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      )}
    </div>
  );
};

export default ContentfulImageView;