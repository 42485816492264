import React, { useState } from 'react';
import {
    Input,Button,Text,
    VStack, Modal,
    ModalOverlay, ModalContent,
    ModalHeader, ModalFooter,
    ModalBody, ModalCloseButton,
    FormControl, FormLabel, useToast
  } from '@chakra-ui/react';

const SupportModal = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isEmailValid, setEmailValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isFormComplete = name && email && message;
  const toast = useToast();

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailValid(validateEmail(e.target.value));
  }

  function submitSupportForm(value, toast) {
    setIsLoading(true);
    console.log("Submitting support form with values:", value);
    
    fetch('https://vaicai.com/api/support/contact/', {
            method: "POST",
            body: JSON.stringify(value, null, 2),
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                console.log("Response received:", response);
                return response.json();
            })
            .then(data => {
                setIsLoading(false);
                console.log("Data parsed:", data);

                if (data.status === 'success') {
                    toast({
                      title: "Message Sent",
                      description: "Soon our support team will get back to you!",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    });
                    window.location.reload(false);
                } else {
                    toast({
                      title: "Message failed to send",
                      description: data.message || "Sorry, we're unable to submit your request at the moment.",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.error("An error occurred:", error);
                toast({
                  title: "Error",
                  description: "An unexpected error occurred.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
            });
}

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"left"}>Need Help With Something?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text textAlign={"left"} mb={4}>Fill out the form below and we'll get back to you as soon as possible!</Text>
            <VStack spacing={4}>
                <FormControl>
                    <FormLabel>Name:</FormLabel>
                    <Input placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} mb={3} />
                </FormControl>
                <FormControl>
                    <FormLabel>Email address:</FormLabel>
                    <Input placeholder="Enter your email address" value={email} onChange={handleEmailChange} mb={3} isInvalid={!isEmailValid} />
                    {!isEmailValid && <Text textAlign={"left"} color="fireEngineRed" fontSize="sm" mb={2}>Please enter a valid email.</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Support issue:</FormLabel>
                    <Input placeholder="Briefly describe your issue..." value={message} onChange={(e) => setMessage(e.target.value)} mb={3} />
                </FormControl>
            </VStack>
        </ModalBody>
        <ModalFooter>
          <Button 
            width={"100%"}
            isLoading={isLoading}
            loadingText="Submitting"
            bg="electricIndigo"
            onClick={() => submitSupportForm({ name, email, message }, toast)} 
            colorScheme="purple" 
            variant={isFormComplete ? "solid" : "outline"}
            isDisabled={!isFormComplete}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SupportModal;
