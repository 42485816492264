import React from 'react';
import {
    Input,Button,Text,
    VStack, Modal,
    ModalOverlay, ModalContent,
    ModalHeader, ModalFooter,
    ModalBody, ModalCloseButton,
    FormControl, FormLabel, useToast, Box, HStack, Container
  } from '@chakra-ui/react';



  class EnterOTP extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            otp: "",
            error: "",
            confirmResult: props.confirmResult,
            code: Array(6).fill(''), // Create an array with 6 empty strings
            currentIndex: 0, // Keep track of the current index
        }
        console.log('EnterOTP constructor')
        this.onSubmitOTP = props.onSubmitOTP.bind(this);
    }

    componentDidMount(){
        console.log(this.state.confirmResult)
    }

    // Handle the change in inputs
  handleChange = (value, index) => {
    const newCode = [...this.state.code];
    newCode[index] = value;
    this.setState({ code: newCode });

    // Automatically move to the next input if the value is filled
    if (value && index < 5) {
      this[`inputRef${index + 1}`].focus();
    }
  };

  // Focus the input when clicked
  handleFocus = (index) => {
    this.setState({ currentIndex: index });
  };

  // Render the inputs
  renderInputs() {
    const inputs = [];

    for (let i = 0; i < 6; i++) {
      inputs.push(
        <Input
          key={i}
          ref={(input) => (this[`inputRef${i}`] = input)}
          value={this.state.code[i]}
          onChange={(e) => this.handleChange(e.target.value, i)}
          onFocus={() => this.handleFocus(i)}
          maxLength="1"
          placeholder=""
          size="lg"
          textAlign="center"
        />
      );
    }

    return inputs;
  }

    render () {
        return (
        <VStack spacing={4}>
            <Text textAlign={"left"} mb={4}>Enter the OTP sent to your phone number</Text>
            <FormControl id="otp" isRequired>
                {/* <Input type="text" /> */}
                <Container centerContent>
                <HStack justify="center" mt={6}>
                    {this.renderInputs()}
                </HStack>
                <Button
                    mt={4}
                    bg="#5F22D9"
                    color="white" 
                    borderRadius="8px"
                    height="48px" 
                    width="100%"
                    onClick={() => this.onSubmitOTP(this.state.code.join(''))}
                    isDisabled={this.state.code.includes('')}
                >
                    Continue
                </Button>
            </Container>
            </FormControl>
        </VStack>
        )

    }

}


export default EnterOTP;