import React from "react";
import logo from './logo.png'; // Tell webpack this JS file uses this image
import './ContactUs.css';

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          message: '',
          type: 'support_email',
        }
    }
    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }

    handleSubmit( event ) {
        event.preventDefault();
        fetch('https://vaicai.com/support/contact', {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response)=> {
                if (response.status === 'success') {
                alert("Message Sent.");
                window.location.reload(false);
            } else if(response.status === 'fail') {
                alert("Message failed to send.")
            }
        })
    }

    render() {
        return (
            <div className="contact-us-container">
                <img src={logo} alt="Logo" className="contact-us-logo"/>
                <span className="contact-us-title">Need Help With Something?</span>
                <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Your Email Address:</label>
                        <input type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Please describe your issue:</label>
                        <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                    </div>
                    <button
                        type="submit"
                        className="submit-btn"
                    >
                            Submit
                    </button>
                </form>
            </div>
        )
    }
}

export default ContactUs;