import React from 'react';
import { Box, HStack, VStack, Text, Image } from '@chakra-ui/react';
import MapView from '../MapView';

const ActivityCell = ({ detail }) => {
  return (
    <HStack spacing={4} p={4} border="1px solid #E2E8F0" borderRadius="md" align="center">
        {detail.imageURL && <Image src={detail.imageURL} alt={detail.name} boxSize="64px" borderRadius="8" />}
      {!detail.imageURL && <MapView latitude={detail.latitude} longitude={detail.longitude} width="64px" height="64px" borderRadius="8" />}
      <VStack align="start" spacing={1}>
        <Text fontWeight="bold" fontSize="md" textAlign="left">{detail.name}</Text>
        <Text fontSize="sm" textAlign="left">{detail.time}</Text>
        <Text fontSize="sm" textAlign="left">{detail.duration}</Text>
      </VStack>
    </HStack>
  );
};

export default ActivityCell;